// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

// Animate.css
@import "~animate.css";

// NProgress
@import "~nprogress/nprogress.css";
#nprogress .bar {
    background: $success;
    height: 4px;
}

#nprogress .spinner-icon {
    border-top-color: $success;
    border-left-color: $success;
}

// Explode2View Editor
@import "~@osp/explode2view-editor/src/scss/validation-data";

// V-viewer     https://github.com/mirari/v-viewer
@import "~viewerjs/dist/viewer.css";


/* Global styling */
h1, h2, h3, h4, h5, h6 {
    margin: 20px 0 30px 0;
}

.hover-pointer:hover {
    cursor: pointer;
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;

    &-xs {
        max-height: 25vh;
    }
    &-sm {
        max-height: 35vh;
    }
    &-md {
        max-height: 50vh;
    }
    &-lg {
        max-height: 75vh;
    }
    &-xl {
        max-height: 100vh;
    }
}

.model-has-updated-icon {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
}
.group-has-updated-icon {
    position: absolute;
    right: 0;
}

.box-shadow {
    box-shadow: 0 0 5rem rgba(0, 0, 0, .5);
}

.required:after {
    content: " *";
}

.modal-full-width {
    max-width: 100%;
}

.mh-btn-sm {
    min-height: 3.4rem;
}

.table-editable {
    tbody > tr {
        td {
            line-height: 2.3rem;
        }
    }
}

.borderless {
    border: none !important;
}

.d-grid {
    display: grid;
}

.grid-auto-fit {
    --min-col-size: 200px;
    grid-template-columns: repeat(auto-fit, minmax(var(--min-col-size), 1fr));
}

.gap-3 {
    gap: 1rem;
}

// Dropdown styling
.dropdown {
    .dropdown-input {
        width: 100% !important;
        max-width: unset !important;

        font-size: 0.9rem !important;
    }
    .dropdown-content {
        width: 100% !important;
        max-width: unset !important;

        .dropdown-item {
            font-size: 0.9rem !important;
        }
    }
}

// Overrides and additions on the Animate.css classes
.animated {
    animation-duration: $animation-duration-short;
}
.delay-short {
    animation-delay: $animation-delay-short-x;
}

// Overrides and additions to Bootstrap classes
.one-btn-width {
    padding-right: 3.75rem !important;
}
.two-btn-width {
    padding-right: 6.75rem !important;
}

.modal-md {
    max-width: 550px;
}

.btn:disabled {
    cursor: not-allowed;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.75);

    &:hover {
        color: #fff !important;
    }
    &.active {
        font-weight: bold;
    }
}

// List animations
.list-complete-item {
    transition: transform $animation-duration-medium-x, opacity $animation-duration-medium-x;
    display: inline-block;
    width: 100%;
}
.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
.list-complete-leave-active {
    position: absolute;
}

// Custom form control styling
.custom-checkbox {
    padding-bottom: 0.5rem;
}
.custom-checkbox label:hover {
    cursor: pointer;
}

.custom-radio label:hover {
    cursor: pointer;
}



/* Maintenance page styling */
.list-group-item-lg {
    .list-group-item {
        line-height: 2.30rem;
    }
}



/* Products table */
#products-table {
    tbody > tr {
        cursor: pointer;

        td {
            line-height: 2.30rem;
        }
    }

    .draggable-ghost {
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 0.5;
    }
}



/* Translations table */
#translations-table {
    tbody > tr > td {
        vertical-align: middle;
    }
}



/* Error message styling */
.error-message {
    min-width: 300px;

    .toast {
        .toast-header {
            .toast-indicator {
                width: 20px;
                height: 20px;
            }
        }

        .toast-body {
            min-height: 45px;
        }
    }
}

.alert-floating {
    position: absolute;
    z-index: 3;
}

.alert-fixed {
    position: fixed;
    z-index: 3;
}



/* OCR progress bar styling */
.ocr-progress-bar {
    width: 100%;
    height: 5px;

    position: absolute;
    bottom: 0;
    left: 0;
}



/* Confirm Buttons styling */
.confirm-button {
    position: absolute;
    right: 20px;
}



/* Header styling */
#logo {
    max-height: 40px;
}



/* Footer styling */
#footer {
    height: 100px;
    background-color: $dark;
    color: #fff;
}
