// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #0055A0; // Bootstrap color: 3490dc
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

/* Custom variables */
// Global variables
$bootstrap-sizes: 'xs', 'sm', 'md', 'lg', 'xl';

// Animation settings
$animation-duration-short-x: 100ms;
$animation-duration-short: 350ms;
$animation-duration-medium: 500ms;
$animation-duration-medium-x: 750ms;
$animation-duration-long: 1s;
$animation-duration-long-x: 1250ms;

$animation-delay-short-x: 100ms;
$animation-delay-short: 350ms;
$animation-delay-medium: 500ms;
$animation-delay-medium-x: 750ms;
$animation-delay-long: 1s;
$animation-delay-long-x: 1250ms;
